import React from "react";
import { Link } from "gatsby";

import { useIntl } from "react-intl";
import { defaultLanguage } from "../../i18n";

const CustomPostsItemSmall = ({ author, title, featuredImage, slug, index }) => {
  
  const intl = useIntl();
  const locale = defaultLanguage === intl.locale ? `/${process.env.GATSBY_DEFAULT_LANG}` : `/${intl.locale}`;
  const link = process.env.GATSBY_MULTILINGUAL === 'true' ? `${locale}/${process.env.GATSBY_CUSTOM_POST_TWO_SLUG}/${slug}/` : `/${process.env.GATSBY_CUSTOM_POST_TWO_SLUG}/${slug}/`
  

  let thumbnail;
  let alt;
  if (featuredImage !== null && featuredImage.node !== null) {
    alt = featuredImage?.node.slug;
    let filteredSizes = featuredImage?.node.mediaDetails.filteredSizes;
    if (filteredSizes.length > 0) {
      let getThumbnail = filteredSizes.filter(
        (img) => img.name === "td_265x198"
      );
      thumbnail = getThumbnail[0]?.sourceUrl;
    } else {
      thumbnail = featuredImage?.node.mediaItemUrl;
    }
  } else {
    thumbnail = "thumb";
    alt = "alt";
  }

  return (
    <li className="custom-post-item-small">
      <Link to={link} className="custom-post-item-small__container">

          <div className="custom-post-item-small__thumbnail">
            <div className="custom-post-item-small__ranking">{index}</div>
            <div className="custom-post-item-small__image"><img loading="lazy" src={thumbnail} alt={alt} /></div>
          </div>
            <div className="custom-post-item-small__info">
            <div className="custom-post-item-small__author">{author}</div>
            <div className="custom-post-item-small__title">{title}</div>
          </div>

      </Link>
    </li>
  );
};

const CustomPostsListSmall = ({ postData }) => {
  
    return (
      <ul className="custom-posts-list-small">
        {postData.map((post, index) => (
          <CustomPostsItemSmall key={post.node.id} author={post.node.custom_field_one.customPostTypeField} title={post.node.title} featuredImage={post.node.featuredImage} slug={post.node.slug} index={index+1} />
         ))}
      </ul>
    );
  };

  export default CustomPostsListSmall;